import * as React from 'react';
import { Formik, FormikProps, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import {
  ContactWrapper,
  ContactPageTitle,
  ContactFromWrapper,
  InputGroup,
} from './style';
import axios from 'axios';

interface MyFormValues {
  firstName: string
  email: string
  message: string
}

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  message: Yup.string().required('Required'),
});

const Contact: React.SFC<{}> = () => {
  return (
    <Formik
      initialValues={{ firstName: "", email: "", message: "" }}
      onSubmit={(
        values: MyFormValues,
        actions: FormikActions<MyFormValues>
      ) => {
        setTimeout(() => {
          const data = {
            email: "sheytanov.simeon@gmail.com",
            variables: [
              {
                "name": "message",
                "value": values.message
              },
              {
                "name": "message-from",
                "value": values.firstName
              },
              {
                "name": "message-email",
                "value": values.email
              }
            ]
          };
          axios
          .post(
            //`https://warwolf-games.com/v1/transactional-campaigns/cbf81381-52ca-4e10-9391-105860c5e780/send`,
            `https://x4glqt6894.execute-api.eu-central-1.amazonaws.com/dev/?url=https://api.bigmailer.io/v1/transactional-campaigns/cbf81381-52ca-4e10-9391-105860c5e780/send`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-API-Key": "ac8ff35a-cf3b-486a-bc57-a4dbc1395227",
              },
            }
          )
          .then(() => {
          })
          .catch(function (error) {
            console.log(error.response);
          });
          actions.setSubmitting(false)
        }, 700)
      }}
      validationSchema={SignupSchema}
      render={({
        handleChange,
        values,
        errors,
        handleBlur,
        touched,
        isSubmitting,
      }: FormikProps<MyFormValues>) => (
        <>
          <Form>
            <ContactWrapper>
              <ContactPageTitle>
                <h2>Contact</h2>
                <p>
                  You can contact us about anything related to the blog or about us. Simply fill the form below and we will answer as soon as possible.
                </p>
              </ContactPageTitle>
              <ContactFromWrapper>
                <InputGroup>
                  <Input
                    type="text"
                    name="firstName"
                    value={`${values.firstName}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Name"
                    notification={`${
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""
                    }`}
                  />
                  <Input
                    type="email"
                    name="email"
                    value={`${values.email}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Email"
                    notification={`${
                      errors.email && touched.email ? errors.email : ""
                    }`}
                  />
                </InputGroup>
                <Input
                  type="textarea"
                  name="message"
                  value={`${values.message}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Message"
                  notification={
                    errors.message && touched.message ? errors.message : ""
                  }
                />
                <Button
                  title="Submit"
                  type="submit"
                  isLoading={isSubmitting ? true : false}
                  loader="Submitting.."
                />
              </ContactFromWrapper>
            </ContactWrapper>
          </Form>
        </>
      )}
    />
  )
}

export default Contact
